<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-client-add"
      ref="modal"
      size="xl"
      title="Dodaj klienta"
      @show="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <AddEditForm
        :form="form"
        :v="$v"
        :state="state.form"
        :handleSubmit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  CLEAR_FOUND_CLIENT,
  CLIENT_ADD,
  CLIENT_LIST
} from "@/core/services/store/client.module";
import { required, requiredIf } from "vuelidate/lib/validators";
import AddEditForm from "../form/client/ClientAddEditForm";

export default {
  name: "client-add-modal",
  components: { AddEditForm },
  data() {
    return {
      state: {
        form: {
          isDeliveryAddress1Enabled: false,
          isDeliveryAddress2Enabled: false
        }
      },
      form: {
        // formState: null,
        // color: "grey",
        // email: "",
        // phone: "",
        // phone2: "",
        // phone3: "",
        // address: {
        //   name: "",
        //   city: "",
        //   postalCode: "",
        //   street: "",
        //   houseNumber: "",
        //   apartmentNumber: ""
        // },
        // deliveryAddress1: {
        //   name: "",
        //   city: "",
        //   postalCode: "",
        //   street: "",
        //   houseNumber: "",
        //   apartmentNumber: ""
        // },
        // deliveryAddress2: {
        //   name: "",
        //   city: "",
        //   postalCode: "",
        //   street: "",
        //   houseNumber: "",
        //   apartmentNumber: ""
        // },
        // nip: "",
        // isVat: false,
        // isInvoiceEmail: false,
        // status: [],
        // size: "",
        // types: [],
        // discountValue: "",
        // attendant: -1,
        // contactPersonFullName: "",
        // agreementsAll: false,
        // agreement1: false,
        // agreement2: false,
        // agreement3: false,
        // lastOrderDate: ""

        formState: null,
        agreementsAll: false,
        agreement1: false,
        agreement2: false,
        agreement3: false,

        nip: "",
        address: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
          apartmentNumber: ""
        },
        isVat: false,
        color: "grey",

        sales_status: [],
        sales_types: [],
        isInvoiceEmail: false,
        sales_size: "",
        sales_discountValue: "",
        sales_attendant: -1,
        sales_email1: "",
        sales_email2: "",
        sales_email3: "",
        sales_phone1: "",
        sales_phone2: "",
        sales_phone3: "",
        sales_contactPersonFullName1: "",
        sales_contactPersonFullName2: "",
        sales_contactPersonFullName3: "",
        deliveryAddress1: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
          apartmentNumber: ""
        },
        deliveryAddress2: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
          apartmentNumber: ""
        },

        lab_status: [],
        lab_matrix: [],
        lab_discountValue: "",
        lab_attendant: -1,
        lab_email1: "",
        lab_email2: "",
        lab_email3: "",
        lab_phone1: "",
        lab_phone2: "",
        lab_phone3: "",
        lab_contactPersonFullName1: "",
        lab_contactPersonFullName2: "",
        lab_contactPersonFullName3: "",
        lab_contactPersonRole1: "",
        lab_contactPersonRole2: "",
        lab_contactPersonRole3: "",

        lastOrderDate: null,
        notices: "",
        nextContactDate: null,

        extra_note: "",
        
        payer: {
          nip: "",
          name: "",
          zip: "",
          city: "",
          street: "",
          houseNumber: ""
        }
      }
    };
  },
  validations() {
    return {
      form: {
        address: {
          name: {
            required
          },
          city: {},
          postalCode: {}
        },
        deliveryAddress1: {
          name: {
            required: requiredIf(function() {
              return this.state.form.isDeliveryAddress1Enabled;
            })
          },
          city: {},
          postalCode: {}
        },
        deliveryAddress2: {
          name: {
            required: requiredIf(function() {
              return this.state.form.isDeliveryAddress2Enabled;
            })
          },
          city: {},
          postalCode: {}
        }
      }
    };
  },
  methods: {
    resetForm() {
      this.state.form.isDeliveryAddress1Enabled = false;
      this.form.formState = null;

      this.form.agreementsAll = false;
      this.form.agreement1 = false;
      this.form.agreement2 = false;
      this.form.agreement3 = false;

      this.form.nip = "";
      this.form.address.name = "";
      this.form.address.city = "";
      this.form.address.postalCode = "";
      this.form.address.street = "";
      this.form.address.houseNumber = "";
      this.form.address.apartmentNumber = "";
      this.form.isVat = false;
      this.form.color = "grey";

      this.form.sales_status = [];
      this.form.sales_types = [];
      this.form.isInvoiceEmail = false;
      this.form.sales_size = "";
      this.form.sales_discountValue = "";
      this.form.sales_attendant = -1;
      this.form.sales_email1 = "";
      this.form.sales_email2 = "";
      this.form.sales_email3 = "";
      this.form.sales_phone1 = "";
      this.form.sales_phone2 = "";
      this.form.sales_phone3 = "";
      this.form.sales_contactPersonFullName1 = "";
      this.form.sales_contactPersonFullName2 = "";
      this.form.sales_contactPersonFullName3 = "";

      this.form.deliveryAddress1.name = "";
      this.form.deliveryAddress1.city = "";
      this.form.deliveryAddress1.postalCode = "";
      this.form.deliveryAddress1.street = "";
      this.form.deliveryAddress1.houseNumber = "";
      this.form.deliveryAddress1.apartmentNumber = "";

      this.form.deliveryAddress2.name = "";
      this.form.deliveryAddress2.city = "";
      this.form.deliveryAddress2.postalCode = "";
      this.form.deliveryAddress2.street = "";
      this.form.deliveryAddress2.houseNumber = "";
      this.form.deliveryAddress2.apartmentNumber = "";

      this.form.lab_status = [];
      this.form.lab_matrix = [];
      this.form.lab_discountValue = "";
      this.form.lab_attendant = -1;
      this.form.lab_email1 = "";
      this.form.lab_email2 = "";
      this.form.lab_email3 = "";
      this.form.lab_phone1 = "";
      this.form.lab_phone2 = "";
      this.form.lab_phone3 = "";
      this.form.lab_contactPersonFullName1 = "";
      this.form.lab_contactPersonFullName2 = "";
      this.form.lab_contactPersonFullName3 = "";
      this.form.lab_contactPersonRole1 = "";
      this.form.lab_contactPersonRole2 = "";
      this.form.lab_contactPersonRole3 = "";

      this.form.lastOrderDate = "";

      this.form.extra_note = "";

      this.form.payer = {
          nip: "",
          name: "",
          zip: "",
          city: "",
          street: "",
          houseNumber: ""
        };

      this.$store.dispatch(CLEAR_FOUND_CLIENT);
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    validateForm() {
      this.$v.form.$touch();
      this.form.formState = !this.$v.form.$invalid;
    },
    handleSubmit() {
      this.validateForm();
      if (this.form.formState) {
        this.$store
          .dispatch(CLIENT_ADD, {
            isDeliveryAddress1Enabled: this.state.form
              .isDeliveryAddress1Enabled,
            isDeliveryAddress2Enabled: this.state.form.isDeliveryAddress2Enabled,

            agreementsAll: this.form.agreementsAll,
            agreement1: this.form.agreement1,
            agreement2: this.form.agreement2,
            agreement3: this.form.agreement3,

            nip: this.form.nip,
            name: this.form.address.name,
            city: this.form.address.city,
            postalCode: this.form.address.postalCode,
            street: this.form.address.street,
            houseNumber: this.form.address.houseNumber,
            apartmentNumber: this.form.address.apartmentNumber,
            color: this.form.color,
            isVat: this.form.isVat,

            sales_status: this.form.sales_status,
            sales_types: this.form.sales_types,
            isInvoiceEmail: this.form.isInvoiceEmail,
            sales_size: this.form.sales_size,
            sales_discountValue: this.form.sales_discountValue,
            sales_attendant: this.form.sales_attendant,
            sales_email1: this.form.sales_email1,
            sales_email2: this.form.sales_email2,
            sales_email3: this.form.sales_email3,
            sales_phone1: this.form.sales_phone1,
            sales_phone2: this.form.sales_phone2,
            sales_phone3: this.form.sales_phone3,
            sales_contactPersonFullName1: this.form.sales_contactPersonFullName1,
            sales_contactPersonFullName2: this.form.sales_contactPersonFullName2,
            sales_contactPersonFullName3: this.form.sales_contactPersonFullName3,
            deliveryName: this.form.deliveryAddress1.name,
            deliveryCity: this.form.deliveryAddress1.city,
            deliveryPostalCode: this.form.deliveryAddress1.postalCode,
            deliveryStreet: this.form.deliveryAddress1.street,
            deliveryHouseNumber: this.form.deliveryAddress1.houseNumber,
            deliveryApartmentNumber: this.form.deliveryAddress1.apartmentNumber,
            deliveryName2: this.form.deliveryAddress2.name,
            deliveryCity2: this.form.deliveryAddress2.city,
            deliveryPostalCode2: this.form.deliveryAddress2.postalCode,
            deliveryStreet2: this.form.deliveryAddress2.street,
            deliveryHouseNumber2: this.form.deliveryAddress2.houseNumber,
            deliveryApartmentNumber2: this.form.deliveryAddress2
              .apartmentNumber,

            lab_status: this.form.lab_status,
            lab_matrix: this.form.lab_matrix,
            lab_discountValue: this.form.lab_discountValue,
            lab_attendant: this.form.lab_attendant,
            lab_email1: this.form.lab_email1,
            lab_email2: this.form.lab_email2,
            lab_email3: this.form.lab_email3,
            lab_phone1: this.form.lab_phone1,
            lab_phone2: this.form.lab_phone2,
            lab_phone3: this.form.lab_phone3,
            lab_contactPersonFullName1: this.form.lab_contactPersonFullName1,
            lab_contactPersonFullName2: this.form.lab_contactPersonFullName2,
            lab_contactPersonFullName3: this.form.lab_contactPersonFullName3,
            lab_contactPersonRole1: this.form.lab_contactPersonRole1,
            lab_contactPersonRole2: this.form.lab_contactPersonRole2,
            lab_contactPersonRole3: this.form.lab_contactPersonRole3,

            lastOrderDate: this.form.lastOrderDate,
            extra_note: this.form.extra_note,

            payer_nip: this.form.payer.nip,
            payer_name: this.form.payer.name,
            payer_zip: this.form.payer.zip,
            payer_city: this.form.payer.city,
            payer_street: this.form.payer.street,
            payer_house_number: this.form.payer.houseNumber,
          })
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch(CLIENT_LIST);
            }, 2000);
          });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-client-add");
          this.$v.$reset();
        });
      }
    }
  }
};
</script>
