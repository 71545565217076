<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <b-button v-b-toggle.collapse-1 variant="primary" class="mt-3"
          >Filtry</b-button
        >
      </h3>
      <div class="card-toolbar">
        <a v-b-modal.modal-client-add class="btn btn-icon btn-light btn-sm">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Code/Plus.svg"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </a>
        <ClientAddModal />
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <b-collapse visible id="collapse-1">
        <div class="row mb-3">
          <div class="col-lg-2">
            <b-form-group label="Nazwa" label-for="name">
              <b-form-input
                id="name"
                placeholder="Podaj nazwę"
                v-model="name"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group
              label="Osoba do kontaktu"
              label-for="contactPersonFullName"
            >
              <b-form-input
                id="contactPersonFullName"
                placeholder="Imię Nazwisko"
                v-model="contactPersonFullName"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Telefon" label-for="phone">
              <b-form-input
                id="phone"
                placeholder="Telefon"
                v-model="phone"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <b-form-group label="NIP" label-for="nip">
              <b-form-input
                id="nip"
                placeholder="Podaj NIP"
                v-model="nip"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <!--            <b-form-group label="Województwo" label-for="voivodeship">-->
            <!--              <b-form-input-->
            <!--                id="voivodeship"-->
            <!--                placeholder="Województwo"-->
            <!--                v-model="voivodeship"-->
            <!--              ></b-form-input>-->
            <!--            </b-form-group>-->
            <b-form-group label="Województwo" label-for="voivodeshipFilter">
              <b-form-select
                id="voivodeshipFilter"
                v-model="voivodeshipFilter"
                :options="voivodeshipList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <ChooseAttendants v-model="attendantFilter"/>
          </div>
          <div class="col-lg-3">
            <b-form-group label="Status" label-for="statusFilter">
              <b-form-select
                id="statusFilter"
                v-model="statusFilter"
                multiple
                :options="statusList"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-collapse>
      <!--begin::Table-->
      <div class="table-responsive">
        <v-data-table
          dense
          v-model="selected"
          :headers="headers"
          :items="dataToShow"
          class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
          item-key="id"
          show-select
          mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Wyników na stronę',
            pageText: '{0}-{1} na {2}',
            itemsPerPageOptions: [10, 30, 50, 100]
          }"
          :items-per-page="10"
        >
          <template v-slot:header="{ headers }">
            <th
              v-for="(header, index) in headers"
              :class="header.key"
              :key="index"
            >
              {{ header.text }}
            </th>
          </template>
          <template v-slot:header.modals="{ header }">
            <th style="width: 0; padding: 0 !important;"></th>
          </template>
          <template v-slot:header.actions="{ header }">
            <div class="text-right">
              {{ header.text }}
            </div>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" :class="isBlacklisted(item) ? 'bg-danger-light' : ''">
                <td style="width: 0; padding: 0 !important;">
                  <ClientEditModal :client="item" />
                  <OrderAddModal :client="item" />
                </td>
                <td style="min-width: 200px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-lg">
                    {{ item.name }}
                  </a>
                </td>
                <td>
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-lg">
                    {{ item.nip }}
                  </a>
                </td>
                <td style="min-width: 80px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-lg">
                    {{ item.contactPersonFullName }}
                  </a>
                </td>
                <td style="min-width: 80px;">
                  <span class="text-dark-75 d-block font-size-lg">
                    {{ getPhones(item) }}
                  </span>
                </td>
                <td style="min-width: 80px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-sm">
                    {{ getEmails(item) }}
                  </a>
                </td>
                <td style="min-width: 20px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-sm">
                    {{ item.isVat === true ? "T" : "N" }}
                  </a>
                </td>
                <td style="min-width: 40px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-sm">
                    {{ item.agreement1 === true ? "T" : "N" }} <br />
                    {{ item.agreement2 === true ? "T" : "N" }} <br />
                    {{ item.agreement3 === true ? "T" : "N" }}
                  </a>
                </td>
                <td>
                  <span class="text-dark-75 d-block font-size-lg">
                    {{ item.postalCode }} {{ item.city }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    Ulica: {{ item.street }} {{ item.houseNumber }}
                    {{ item.apartmentNumber }}<br />
                    {{ getVoivodeship(item) }}<br />
                  </span>
                </td>
                <td style="min-width: 40px;">
                  <a class="text-dark-75 text-hover-primary mb-1 font-size-sm">
                    {{ getTranslatedAttendant(item.attendant) }}
                  </a>
                </td>
                <td class="text-right" style="min-width: 150px;">
                  <a class="btn btn-icon btn-light btn-sm" @click="order(item)">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    @click="edit(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    @click.prevent="remove(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CLIENT_SOFT_DELETE,
  CLIENT_LIST
} from "@/core/services/store/client.module";
import ClientAddModal from "@/view/content/modal/ClientAddModal.vue";
import ClientEditModal from "@/view/content/modal/ClientEditModal.vue";
import OrderAddModal from "@/view/content/modal/OrderAddModal.vue";
import Swal from "sweetalert2";
import Ripple from "vuetify/lib/directives/ripple";
import Vuetify from "vuetify";
import _ from "lodash";
import ChooseAttendants from "@/view/content/form/ChooseAttendants";
import {USER_LIST} from "@/core/services/store/user.module";
import {FERM_LIST} from "@/core/services/store/ferm.module";
export default {
  name: "client-table",
  components: { ClientAddModal, ClientEditModal, OrderAddModal, ChooseAttendants },
  directives: {
    Ripple
  },
  vuetify: new Vuetify(),
  data() {
    return {
      name: "",
      nip: "",
      phone: "",
      contactPersonFullName: "",
      attendantFilter: [],
      voivodeshipList: [
        { value: "-select-", text: "Wybierz" },
        { value: "dolnośląskie", text: "dolnośląskie" },
        { value: "kujawsko-pomorskie", text: "kujawsko-pomorskie" },
        { value: "lubelskie", text: "lubelskie" },
        { value: "lubuskie", text: "lubuskie" },
        { value: "łódzkie", text: "łódzkie" },
        { value: "mazowieckie", text: "mazowieckie" },
        { value: "małopolskie", text: "małopolskie" },
        { value: "opolskie", text: "opolskie" },
        { value: "podkarpackie", text: "podkarpackie" },
        { value: "podlaskie", text: "podlaskie" },
        { value: "pomorskie", text: "pomorskie" },
        { value: "śląskie", text: "śląskie" },
        { value: "świętokrzyskie", text: "świętokrzyskie" },
        { value: "warmińsko-mazurskie", text: "warmińsko-mazurskie" },
        { value: "wielkopolskie", text: "wielkopolskie" },
        { value: "zachodniopomorskie", text: "zachodniopomorskie" }
      ],
      voivodeshipFilter: "-select-",
      singleSelect: false,
      statusFilter: [],
      statusList: [
        { value: null, text: "Brak" },
        { value: "active", text: "Aktywny" },
        { value: "inactive", text: "Nieaktywny" },
        { value: "laboratory", text: "Laboratorium" },
        { value: "progres", text: "Progres" },
        { value: "end_of_breeding", text: "Koniec hodowli" },
        { value: "blacklisted", text: "Czarna lista" }
      ],
      selected: [],
      headers: [
        // { text: "", value: "modals", sortable: false },
        {
          text: "Nazwa firmy lub Imię i Nazwisko klienta",
          value: "name",
          filter: value => {
            if (!this.name) return true;
            return value.toLowerCase().includes(this.name.toLowerCase());
          },
          style: "min-width: 200px;"
        },
        {
          text: "NIP",
          value: "nip",
          filter: value => {
            if (!this.nip) return true;
            return value.toLowerCase().includes(this.nip.toLowerCase());
          }
        },
        {
          text: "Osoba do kontaktu",
          value: "contactPersonFullName",
          filter: value => {
            if (!this.contactPersonFullName) return true;
            return value
              .toLowerCase()
              .includes(this.contactPersonFullName.toLowerCase());
          },
          style: "min-width: 80px;"
        },
        {
          text: "Telefony",
          value: "phone",
          filter: value => {
            if (!this.phone) return true;
            return value.toLowerCase().includes(this.phone.toLowerCase());
          },
          style: "min-width: 80px;",
          sortable: false
        },
        {
          text: "Mail",
          value: "email",
          style: "min-width: 80px;"
        },
        {
          text: "VAT",
          value: "isVat",
          style: "min-width: 20px;"
        },
        {
          text: "RODO",
          value: "rodo",
          style: "min-width: 40px;"
        },
        {
          text: "Adres",
          value: "voivodeship",
          filter: value => {
            if (this.voivodeshipFilter === "-select-") return true;
            if (!this.voivodeshipFilter) return false;
            if (value === null || value === undefined) return false;
            return value.toLowerCase() === this.voivodeshipFilter.toLowerCase();
          }
        },
        {
          text: "Operator",
          value: "attendant",
          filter: value => {
            if (this.attendantFilter.length === 0) return true;
            if (value === null) return false;
            return _.includes(this.attendantFilter, value);
          }
        },
        {
          text: "Status",
          value: "status",
          filter: statuses => {
            //no statuses selected in filter -> show all
            if (this.statusFilter.length === 0) return true;
            //statuses of client are null instead of object -> this is error -> hide this client as it is broken
            if (statuses === null) return false;
            // below was used when all statuses from filter must match
            // return _.difference(this.statusFilter, statuses).length === 0;
            // below was used when all statuses from filter must match
            // return (
            //     this.statusFilter.length !==
            //     _.difference(this.statusFilter, statuses).length
            // );
            if (_.includes(this.statusFilter, null)) {
              let filters = _.without(this.statusFilter, null);
              if (filters.length === 0) {
                return statuses.length === 0;
              } else {
                return (
                  filters.length !== _.difference(filters, statuses).length ||
                  statuses.length === 0
                );
              }
            }
            //below was used when any status from filter must watch
            return (
              this.statusFilter.length !==
              _.difference(this.statusFilter, statuses).length
            );
          },
          sortable: false,
          align: " d-none"
        },
        {
          text: "Akcje",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(CLIENT_LIST);
    this.$store.dispatch(USER_LIST);
    this.$store.dispatch(FERM_LIST);
  },
  methods: {
    remove(id) {
      Swal.fire({
        title: "Na pewno usunąć?",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(CLIENT_SOFT_DELETE, { id });
        }
      });
    },
    edit(item) {
      this.$bvModal.show("modal-client-edit-" + item.id);
    },
    order(item) {
      this.$bvModal.show("order-add-modal-" + item.id);
    },
    getVoivodeship(item) {
      if (
        item.voivodeship !== "" &&
        item.voivodeship !== null &&
        typeof item.voivodeship !== "undefined"
      ) {
        return "woj. " + item.voivodeship;
      }
      return "";
    },
    getTranslatedAttendant(attId) {
      const a = _.find(this.attendantsList, { value: attId });
      if (!_.isUndefined(a)) return a.text;
      return "";
    },
    getPhones(item) {
      return item.sales_phone1 + " " + item.sales_phone2 + " " + item.sales_phone3;
    },
    getEmails(item) {
      return item.sales_email1 + " " + item.sales_email2 + " " + item.sales_email3;
    },
    isBlacklisted(item) {
      return _.includes(item.status, "blacklisted");
    }
  },
  computed: {
    ...mapGetters(["clients", "users"]),
    dataToShow() {
      // if ( this.clients.length == 0 )
      //   return [
      //     {
      //       name: "",
      //       status: "",
      //       attendant: "",
      //       voivodeship: "",
      //       phone: "",
      //       contactPersonFullName: "",
      //       nip: ""
      //     }
      //   ];

      return this.clients;
    },
    attendantsList() {
      let result = [
        {
          value: -1,
          text: "Brak"
        }
      ];
      this.users.forEach(user => {
        result.push({
          value: user.id,
          text: user.firstName + " " + user.lastName
        });
      });
      return result;
    }
  },
};
</script>
